import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Image from "../components/image"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Alice in Wonderland"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <div style={{ maxWidth: `630px`, marginBottom: `1.45rem` }}>
          <Image />
        </div>
        <h1>
          Hallo
        </h1>
        <p>Welkom op mijn blog.</p>
        <p>
          U kunt hier lezen over mijn ervaringen als persoonlijk coach.
        </p>
        <Link to="/blog/">
          <Button marginTop="35px">Lees mijn Blog</Button>
        </Link>
      </Layout>
    )
  }
}

export default IndexPage
